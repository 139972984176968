/** 常用色值 **/
/** 常用字体大小 **/
/** load **/
.ajax-loading {
  display: none;
}
.ajax-loading .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  color: #fff;
}
.ajax-loading .loading img {
  width: 32px;
  vertical-align: middle;
}
.ajax-loading .loading span {
  margin-left: 12px;
}
.ajax-loading .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  background: #ffffff;
  opacity: 0.1;
}
/** 常用色值 **/
/** 常用字体大小 **/
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);
  background-color: #f9f9f9;
}
.ant-select-disabled .ant-select-selection {
  background: #f9f9f9;
  cursor: not-allowed;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.8);
}
.ant-radio-disabled + span {
  color: #000000;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #e2e2e2;
  border-color: #e2e2e2 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgb(47 47 0.72307692%);
}
