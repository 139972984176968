/** 常用色值 **/
/** 常用字体大小 **/
.user {
  padding: 10px 30px;
}
.userArea {
  padding: 10px 30px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);
  background-color: #f9f9f9;
}
.ant-select-disabled .ant-select-selection {
  background: #f9f9f9;
  cursor: not-allowed;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.8);
}
.ant-radio-disabled + span {
  color: #000000;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #e2e2e2;
  border-color: #e2e2e2 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgb(47 47 0.72307692%);
}
