/** 常用色值 **/
/** 常用字体大小 **/
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 14px 6px;
  text-align: center;
}
.ant-table .ant-table-selection-column {
  min-width: 42px !important;
  width: 42px !important;
}
.ant-table .ant-table-thead > tr > th {
  text-align: center;
}
.ant-table .text-center {
  text-align: center;
}
.ant-table .text-left {
  text-align: left;
}
.ant-table.ant-table-middle-thead > tr > th,
.ant-table.ant-table-middle-tbody > tr > td {
  padding: 10px 6px;
}
.ant-table.ant-table-small-thead > tr > th,
.ant-table.ant-table-small-tbody > tr > td {
  padding: 8px 6px;
}
.ant-table-pagination {
  padding: 0 20px;
}
