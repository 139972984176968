/** 常用色值 **/
/** 常用字体大小 **/
@import '../../../node_modules/antd/dist/antd.css';
.login {
  width: 100%;
  height: 100%;
  min-height: 550px;
  background: url("backgroung.png") no-repeat;
  background-size: 100% 100%;
}
.login .login-left {
  position: absolute;
  width: 40%;
  float: left;
}
.login .login-left .login-left-body {
  border: 2px solid #d6d6d6;
  padding: 80px 70px;
  margin: 80px;
  text-align: center;
}
.login .login-left .login-left-body h2 {
  color: white;
  font-size: 40px;
}
.login .login-right {
  position: absolute;
  width: 60%;
  float: left;
  margin-left: 40%;
}
.login .login-right .login-right-body {
  height: 420px;
  margin: 50px 200px;
  padding: 20px 30px;
  background: rgba(214, 214, 214, 0.7);
}
.login .login-right .login-right-body .login-form {
  width: 100%;
}
.login .login-right .login-right-body .login-form .login-title {
  text-align: center;
}
.login .login-right .login-right-body .login-form .login-form-button {
  width: 100%;
  height: 35px;
}
.login .login-right .login-right-body .login-form form div:nth-child(4) {
  margin: -25px 0 10px;
}
.login footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-left: 30px;
  color: #fff;
}
.login footer p:first-child {
  float: left;
  margin-top: 15px;
}
.login footer p:last-child {
  float: left;
  margin-left: 50%;
  margin-top: 15px;
}
.login-form .login-tip {
  margin-top: 20px;
}
