/** 常用色值 **/
/** 常用字体大小 **/
.header {
  width: 100%;
  height: 100%;
}
.header .header-top {
  width: 100%;
  height: 80px;
  /* 底色 */
  background-color: #045DA2;
  /* chrome 2+, safari 4+; multiple color stops */
  /* chrome 10+, safari 5.1+ */
  /* firefox; multiple color stops */
  /* ie 6+ */
  /* ie8 + */
  /* ie10 */
  /* opera 11.1 */
  /* 标准写法 */
}
.header .header-top img {
  height: 56px;
  margin: 12px 20px;
  float: left;
}
.header .header-top span:nth-child(3) {
  font-size: 30px;
  color: white;
  float: left;
  margin: 16px 0 0 20px;
  font-weight: bolder;
  font-family: "KaiTi";
}
.header .header-top span:nth-child(6) {
  float: right;
  margin: 20px 10px;
  font-size: 16px;
  color: white;
  font-weight: bold;
}
.header .header-top .header-role {
  float: right;
  margin: 20px 0;
}
.header .header-top .header-role img {
  height: 30px;
  margin: -3px;
}
.header .header-top .header-logout {
  float: right;
  margin: 20px 60px;
  font-size: 16px;
}
.header .breadcrumb {
  height: 30px;
  background: #045DA2;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 30px;
  line-height: 30px;
  background: #353535 !important;
  color: #FFFFFF !important;
  margin-right: 0;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 30px;
  line-height: 30px;
  background: #1A1A1A;
  color: #727272;
  margin-right: 0px;
  border-top: none;
  border-left: none;
  border-right: 1px solid #FFFFFF;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
  color: #FFFFFF;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
  margin: 0 10px;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  color: #FFFFFF;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: -10px;
}
.header .breadcrumb .ant-tabs-bar {
  margin: 0;
}
.header .home {
  height: 450px;
  color: white;
  margin: 0 80px;
  padding: 3px 0;
  font-size: medium;
  text-align: center;
}
.header .home .module-top {
  height: 280px;
  margin: 0 5px;
  line-height: 280px;
}
.header .home .module-top .module-info {
  height: 90px;
  width: max-content;
  line-height: 90px;
}
.header .home .module-top1 {
  height: 135px;
  margin: 0 5px;
  line-height: 135px;
}
.header .home .module-bottom {
  height: 140px;
  margin: 0 5px;
  line-height: 140px;
}
.ant-drawer-content-wrapper {
  margin-top: 110px;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body {
  background: #001529;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-title {
  margin: 0;
  color: #fcfdfd;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-header {
  position: relative;
  padding: 16px 25px;
  color: rgba(0, 0, 0, 0.65);
  background: #001529;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-header .ant-drawer-title {
  padding-left: 25px;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
  font-size: 14px;
  background: #001529;
  line-height: 1.5;
  word-wrap: break-word;
  flex-grow: inherit;
  overflow: auto;
  height: 480px;
}
.ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
