/** 常用色值 **/
/** 常用字体大小 **/
.role {
  padding: 10px 30px;
}
.role .role-table {
  margin-top: 25px;
}
.role .role-table button {
  margin-right: 10px;
}
.role-action {
  padding: 10px;
}
.role-action .ant-checkbox-group {
  margin-top: 10px;
  padding: 10px;
}
.role-action .ant-checkbox-group-item {
  width: 100%;
  margin-bottom: 10px;
}
